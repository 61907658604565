import Accordion from 'react-bootstrap/Accordion';

export default function OrdersAccordion({years, selectedYear, month} ) {
  // alwaysOpen - ei sulje muita kortteja 

  return (
      <div>
        { selectedYear && month && 
        <Accordion alwaysOpen>
          {  Object.entries(years[selectedYear][month].orders).map((entry) => {
              const company_name = entry[0];
              const orders = entry[1];
              var cardCounter = 0;

            return (
              <Accordion.Item eventKey={company_name} key={company_name}>
                  <Accordion.Header><h2>{company_name}</h2></Accordion.Header>
                  <Accordion.Body>

                  { Object.entries(orders).map((data) => {
                      cardCounter++;
                      var label = data[1][0].delivery_date + " kello " + data[1][0].delivery_time;
                      var order_rows = data[1];
                      
                      return (
                        <div key={`${company_name}_${cardCounter}`} className='card mb-3'>
                          <div className="card-header" aria-expanded="true">
                            <label className="mb-0">Toimituspäivä: {label}</label>
                          </div>
                          <div id="card_body_` + company_index + `_` + index + `" className="card-body">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th style={{'width': '15%'}}>Tuotekoodi</th>
                                    <th style={{'width': '75%'}}>Nimi</th>
                                    <th style={{'width': '10%'}}>Lkm</th>
                                </tr>
                                </thead>
                                <tbody id="table_body_` + company_index + `_` + index + `">
                                  { Object.entries(order_rows).map(([i, element]) => {
                                    return (
                                    <tr key={`${element.product_code}_{i}`}>
                                      <td>{ element.product_code }</td>
                                      <td>{ element.product_desc }</td>
                                      <td>{ element.product_amount }</td>
                                    </tr>
                                    )
                                  })}
                                </tbody>
                            </table>
                        </div>
                        </div>
                      )
                    })
                  }
                  </Accordion.Body>
              </Accordion.Item>
          )
          })}
        </Accordion>
        }
      </div>
      );
}

