export function fetchRequest(cookies_token, amount, invoice_id) {
    return new Promise(async (resolve) => {
        var headers = headersWrapper(cookies_token);

        fetch(process.env.REACT_APP_API_URL + "/v1/customer/postrequest" , {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({amount: amount, invoice_id: invoice_id }),
          })
            .then(res => res.json())
            .then(
              (result) => {
                if (result.error){
                  if (result.data === 'pending') {
                    resolve({ error: true, msg: "Tuotteelle on tehty jo muutospyyntö. Odota että aiempi pyyntö käsitellään tai soita meille numeroon 010 235 8800*", data: null });
                  }
                  else if (result.data === 'amount_error') {
                    resolve({ error: true, msg: "Ole hyvä ja tarkista syöttämäsi tuotteen määrä.", data: null });
                  }
                  else if (result.data === 'date_error') {
                    resolve({ error: true, msg: "Tilausta ei voi valitettavasti enää muuttaa. Muutospyyntö olisi pitänyt jättää " + result.info + " mennessä. \n\nMikäli on tapahtunut virhe tai kyseessä on erikoistapaus voit soitaa meille numeroon 010 235 8800*.", data: null });
                  }
                  else {
                    resolve({ error: true, msg: "Tapahtui virhe. Yritä uudelleen tai mikäli virhe toistuu soita meille numeroon 010 235 8800*", data: null });
                  }
                } else {
                    resolve({ error: false, msg: "OK", data: null});
                }
              },
              (error) => {
                resolve({ error: true, msg: "Tapahtui virhe. Yritä uudelleen tai mikäli virhe toistuu soita meille numeroon 010 235 8800*", data: null });
              }
            )
    })
}

export function fetchOrders(cookies_token) {

    return new Promise(async (resolve) => {
        var headers = headersWrapper(cookies_token);

        await fetch(process.env.REACT_APP_API_URL + "/v1/orders/get_open_orders", {
            method: 'GET',
            headers: headers
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        throw result.error.message;
                    }
                    resolve({ error: false, msg: 'OK', data: result });
                },
                (error) => {
                    resolve({ error: true, msg: "Tapahtui virhe. Yritä uudelleen tai mikäli virhe toistuu soita meille numeroon 010 235 8800*", data: null });
                }
            )
            // Ottaa kiinni aiemmin heitetyn virheen
            .catch(function (error) {
                resolve({ error: true, msg: error, data: null });
            });
    });
}

// Hakee tuoteselosteen
export function getProductDescription(id, name, cookies_token) {

    return new Promise(async (resolve) => {
        var headers = headersWrapper(cookies_token);
        await fetch(process.env.REACT_APP_API_URL + "/v1/customer/getdata?" + new URLSearchParams({ id: id }), {
            method: 'GET',
            headers: headers
        })
        .then(res => res.json())
        .then(
        (result) => {
            if (result.error) {
              alert ("Tapahtui virhe: " + result.msg)
            }
            else {
                resolve({ error: false, msg: 'OK', data: result });
            }
        },
        (error) => {
            resolve({ error: true, msg: error, data: null });
        }
    )
    });
}


export function getAllOrdered(cookies_token) {

  return new Promise(async (resolve) => {
    var headers = headersWrapper(cookies_token);

    await fetch(process.env.REACT_APP_API_URL + "/v1/orders/get_all_ordered_products", {
        method: 'GET',
        headers: headers
    })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.error) {
                    throw result.error.message;
                }
                resolve({ error: false, msg: 'OK', data: result });
            },
            (error) => {
                resolve({ error: true, msg: "Tapahtui virhe. Yritä uudelleen tai mikäli virhe toistuu soita meille numeroon 010 235 8800*", data: null });
            }
        )
        // Ottaa kiinni aiemmin heitetyn virheen
        .catch(function (error) {
            resolve({ error: true, msg: error, data: null });
        });
});


}

function headersWrapper (cookies_token) {
  var headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + cookies_token);

  return headers; 
}